//
// Components
//

// Import Dependencies
@import 'landing';

//
// Component DeviceAttributeSelector
//
.componentDeviceAttributeSelector {
  .defaultStyle {
    width: 100%;
    padding: 8px 8px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
}

